<template>
  <div class="selected-collections-page">
    <PageHeader :title="title" />

    <InfinityScroll
        v-if="selectedCollections && selectedCollections.length"
        :items="selectedCollections"
        :has-more="hasMoreSelectedCollections"
        @rich-end="loadMore"
    >
      <template #default="{ item }">
        <FollowingUser :user="item" class="selected-collections-page__user" />
      </template>
    </InfinityScroll>
  </div>
</template>

<script>
import FollowingUser from "@/features/components/FollowingUser.vue";
import PageHeader from "@/layout/PageHeader.vue";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import { mapGetters, mapMutations } from "vuex";
import InfinityScroll from "@/features/components/InfinityScroll.vue";

const postsCallbackType = {
  setSelectedCollections: "setSelectedCollections",
  updateSelectedCollections: "updateSelectedCollections",
};

export default {
  name: "SelectedCollectionsPage",
  components: { InfinityScroll, PageHeader, FollowingUser },
  mixins: [waitRequest, validationError],

  computed: {
    ...mapGetters({
      selectedCollections: "selectedCollections/selectedCollections",
      selectedCollectionsMeta: "selectedCollections/selectedCollectionsMeta",
    }),

    hasMoreSelectedCollections() {
      return Boolean(this.selectedCollectionsMeta?.next_page_url);
    },

    page() {
      return this.selectedCollectionsMeta?.current_page || 1;
    },
  },

  data() {
    return {
      title: '',
      listId: null,
      loadSelectedCollectionsCallback: {
        [postsCallbackType.setSelectedCollections]: this.setCurrentPosts,
        [postsCallbackType.updateSelectedCollections]: this.updateCurrentPosts,
      },
    };
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      setSelectedCollections: "selectedCollections/setSelectedCollections",
      updateSelectedCollections: "selectedCollections/updateSelectedCollections",
      setMeta: "selectedCollections/setMeta",
    }),

    initState() {
      this.listId = this.$route?.params?.id
      this.title = this.$route.query.title;
      this.$router.replace(`/collections/${this.listId}`)
      this.initialLoadSelectedCollections();
    },

    setCurrentPost(data) {
      this.setSelectedCollections({ selectedCollections: [data] });
    },

    loadSelectedCollections(successCallbackType = postsCallbackType.setSelectedCollections) {
      if (this.requestInProgress) return;

      const successCallback = this.loadSelectedCollectionsCallback[successCallbackType];
      const requestUrl = `/lists/${this.listId}`;

      return this.waitRequest(() => {
        return this.$get(
            requestUrl,
            successCallback,
            this.loadPostError,
            this.checkErrors
        );
      });
    },

    async initialLoadSelectedCollections() {
      this.$showSpinner();

      this.loadSelectedCollections(postsCallbackType.setSelectedCollections);
    },

    loadMore() {
      if (this.hasMoreSelectedCollections)
        return this.loadSelectedCollections(postsCallbackType.updateSelectedCollections);
    },

    setCurrentPosts(data) {
      const { current_page, data: selectedCollections } = data;

      this.setSelectedCollections({ selectedCollections });
      this.setMeta({ meta: data });

      this.initialPage = current_page;
    },

    updateCurrentPosts(data) {
      const selectedCollections = data.data;

      this.updateSelectedCollections({ selectedCollections });
      this.setMeta({ meta: data });
    },

    loadPostError(errors) {
      console.log(errors);
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-collections-page {
  &__user {
    padding: em(15) em(15);
  }
}
</style>
